import http from '@/utils/http';


export const LineAddTextlive = (parameter) => {
    return http.request({
      url: '/v1/text_live/line/add',
      method: 'post',
      data: parameter
    })
  }
  
export const LineUpdateTextlive = (parameter) => {
    return http.request({
        url: '/v1/text_live/line/update',
        method: 'post',
        data: parameter
    })
}

export const LineDeleteTextlive = (parameter) => {
    return http.request({
        url: '/v1/text_live/line/delete',
        method: 'post',
        data: parameter
    })
}
