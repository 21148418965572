<template>
    <!-- 线上文字直播的编辑 -->

    <div style="width:100%">
        <Modal v-model="panel_add_log" title="新增/编辑文字直播" @on-ok="apiAddLog()">
            <div  style="background:#8FBC8B;padding:1px">
                <Card >
                    <Row>
                        <Col >
                            <Form  :label-width="100" style="margin-top:0px;" @submit.native.prevent>
                                
                            </Form>
                        </Col>
                    </Row>
                    <Row>
                        <Form :label-width="100" style="margin-top:0px;" @submit.native.prevent>
                            <FormItem label="小节时间:" >
                                <Duration v-model="new_log.time_left" ></Duration>
                            </FormItem>
                            <FormItem label="主客比分:">
                                <Row>
                                    <Col span=8>
                                        <Input v-model="new_log.home_score" />
                                    </Col>
                                    <Col span=3>
                                        <span v-if="new_log.team===1 && new_log.log_score>0" class="log_score">+{{new_log.log_score}}</span>
                                    </Col>
                                    <Col span=8>
                                        <Input v-model="new_log.away_score" />
                                    </Col>
                                    <Col span=3>
                                        <span v-if="new_log.team===2 && new_log.log_score>0" class="log_score">+{{new_log.log_score}}</span>
                                    </Col>
                                </Row>
                            </FormItem>
                            <FormItem label="中文:">
                                <Input v-model="log_desc_zh" type='text' />
                            </FormItem>
                            <FormItem label="英文:">
                                <Input v-model="log_desc_en" type='text' />
                            </FormItem>
                            <FormItem label="分边:">
                                <Select v-model="new_log.team" >
                                    <Option v-for="item in teamList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                                </Select>
                            </FormItem>
                        </Form>
                    </Row>
                    <Spin size="large" fix v-if="spinShow"></Spin>
                </Card>
            </div>
        </Modal>
        <!-- <Button type="info" @click="modal_add=true" long>新增情报</Button> -->
        <!-- <Button type="primary" @click="add_a_word_to_log($event)" data-shortkey="a0" >跳跃</Button> -->
        <Row :gutter="10" style="margin:3px 0 3px 0;text-align: center;" >
            <Col style="border-right: 0px solid black;" >
                <template >
                    <div >
                        <Tabs v-model="box_num" type="card"  >
                        <TabPane v-for="(item, index) in tab_box_nums" :key="item.name" :label="item.label" :name="item.name" >
                            <Row>
                                <Col span="24" style="border-right: 1px solid black;" >
                                    <Row>
                                        <Button  @click="showAddModel()" long type='warning'>新增</Button>
                                        <!-- <Button type="info"  @click="model_replace=true">替换</Button> -->
                                    </Row>
                                    <Table
                                        @on-selection-change="apiSelectChange"
                                        :row-class-name="rowClassName"
                                        border ref="selection"
                                        :columns="columns_log"
                                        :data="text_lives[item.name]"
                                        :draggable="false"
                                        context-menu
                                        show-context-menu
                                        @on-contextmenu="handleContextMenu"
                                        >
                                        <template slot="contextMenu">
                                            <DropdownItem @click.native="handleContextMenuInsertUp">插入到本条上行</DropdownItem>
                                            <DropdownItem @click.native="handleContextMenuInsertDown">插入到本条下行</DropdownItem>
                                        </template>
                                    </Table>
                                </Col>
                            </Row>
                        </TabPane>

                        </Tabs>
                    </div>
                </template>
            </Col>
        </Row>
    </div>

</template>

<script>

import { LineAddTextlive, LineUpdateTextlive, LineDeleteTextlive, } from '@/api/sports/text_live';
import { GetTextlive,GetTabsList } from '@/api/sports/match';
import moment from 'moment';
import Duration from '@/components/basic/duration.vue';

export default {
  data () {
    let self = (window.x = this)
    return {
        spinShow: false,
        mouseStartPos: null,
        teamList: [
            {
                value: 0,
                label: '中立'
            },
            {
                value: 1,
                label: '主队'
            },
            {
                value: 2,
                label: '客队'
            },
        ],
        new_log: {
            "key_index": -1,
            "time_left": 0,
            "home_score": 0,
            "away_score": 0,
            "team": 0,  // 0中立 1主队 2客队
            "neighborkey_zh": '',
            "neighborkey_en": '',
            "insert": 0
        },
        log: "",
        log_desc_zh:'',
        log_desc_en:'',
        log_map: {},
        tab_box_nums :[{
            "name": "1",
            "label": "第1节",
            show: true,
        },{
            "name": "2",
            "label": "第2节",
            show: true,
        },{
            "name": "3",
            "label": "第3节",
            show: true,
        },{
            "name": "4",
            "label": "第4节",
            show: true,
        },],

        box_num: '1',
        model_replace: false,
        panel_add_log: false,
        sport_id: 0,
        match_id: 0,
        home: '',
        away: '',
        loading: true,
        data: {
            home: {
                short_name_zh: '',
            },
            away: {
                short_name_zh: '',
            },
            away_players: [],
            home_players: [],
            textlive_status: "0",
            bing_translates: [],
            competition: {
                short_name_zh: '',
            },
        },

        // 文字直播
        text_lives: {
            "1": [],"2": [],"3": [],"4": [],"5": [],
        },
        nami_text_lives: {
            "1": [],"2": [],"3": [],"4": [],"5": [],
        },
        columns_log: [
            // {
            //     type: 'selection',
            //     width: 60,
            //     align: 'center'
            // },
            {
                title: '时间与比分',
                width: 180,
                key: 'score_infos',
                render: function (h, params) {
                    let currentRow = params.row
                    return h(
                        'div',  [
                            // h('Avatar', { attrs: { src: self.$config.imageUrl + user.avatar, shape: 'square', size: 'large' } }),
                            // h('span', user.name)
                            h('Row', '倒计时: ' + currentRow.time_left_str),
                            // 比分
                            h("span", "比 分: "),
                            h('span', {
                                style:{
                                    'width':'45%',
                                },
                            }, currentRow.home_score),
                            h('span', {
                                style:{},
                                }, ' - '),
                            h('span', {
                                style:{
                                    'width':'45%',
                                },
                            }, currentRow.away_score),
                        ]
                    )
                }
            },
            {
                title: '描述',
                key: 'desc_zh',
                render: function (h, params) {
                    let currentRow = params.row
                    return h(
                        'div',  [
                            // h('Avatar', { attrs: { src: self.$config.imageUrl + user.avatar, shape: 'square', size: 'large' } }),
                            // h('span', user.name)
                            h('Row', '中文: ' + currentRow.desc_zh),
                            h('Row', '英文: ' + currentRow.desc_en),
                        ]
                    )
                }
            },
            {
                title: '操作',
                width: 160,
                render: function (h, params) {
                    let currentRow = params.row
                    return h(
                        'div',  [
                            h('Button', {
                                props: {
                                    type: 'primary',
                                    size: 'small'
                                },
                                on: {
                                    click: () => {
                                        self.log_desc_zh = currentRow.desc_zh;
                                        self.log_desc_en = currentRow.desc_en;
                                        self.new_log =  {
                                            "key_index": currentRow.key_index,
                                            "time_left": currentRow.time_left,
                                            "home_score": currentRow.home_score,
                                            "away_score": currentRow.away_score,
                                            "team": currentRow.team,  // 0中立 1主队 2客队
                                            "neighborkey_zh": '',
                                            "neighborkey_en": '',
                                            "insert": 0,
                                            "raw": currentRow.raw,
                                        }
                                        self.panel_add_log = true;
                                    },
                                },
                            }, 
                            "编辑"
                            ),
                            h('Poptip',
                                {
                                    props: {
                                        confirm: true,
                                        title: '您确定要删除这条数据吗?',
                                        transfer: true
                                    },
                                    on: {
                                        'on-ok': () => {
                                            self.deleteLogs(currentRow.key_index);
                                        }
                                    }
                                },
                                [
                                    h('Button',
                                        {
                                            style: {
                                                // margin: "0 5px"
                                            },
                                            props: {
                                                type: 'error',
                                                size: "small"
                                            }
                                        },
                                        '删除'
                                    )
                                ]
                            )
                        ]
                    )
                }
            },
        ],
        choosed_logs: {},  // 选中的文字直播
        get_data_count:0,
    }
  },
  components: {
    Duration,
  },
  methods: {
    //end
    getTabsData () {
      let self = this;
      var params = {
        sport_id: self.sport_id,
        match_id: self.match_id,
      }
      GetTabsList(params).then(response => {
        if (response.data.code == 0) {
          var data = response.data.data;
          this.loading = false;
          // 生成小节数
          var old_box_nums = []
          for ( var _index in self.tab_box_nums){
              // console.log(tab)
              old_box_nums.push( self.tab_box_nums[_index].name );
          }
          var need_update_tab = false;
          for (var _index in data.list){
              if (! old_box_nums.includes( data.list[_index].name )  ){
                need_update_tab = true;
                break
              }
          }
          if (need_update_tab){
            self.tab_box_nums = data.list;
          }
        } else {
          self.$Message.error(response.data.msg)
        }
      })
    },

    // 获取文字直播
    apiGetLog(_type, box_num){
        let self = this;
        var params = {
            sport_id:self.sport_id,
            match_id: self.match_id,
            box_num: box_num,
            type: _type,
        }
        GetTextlive( params ).then(response => {
          if (response.data.code == 0) {
            // 重新获取文字直播
            if (_type == 2){
              // self.text_lives = response.data.data;
              for (var val in response.data.data){
                  self.$set(self.text_lives, val, response.data.data[val] );
                  // 当前局的直播列表 - 解决最后一条删除之后 数据依然存在的bug
              }
            // console.log(self.text_lives);
            }else{
              // self.nami_text_lives = response.data.data;
              for (var val in response.data.data){
                  self.$set(self.nami_text_lives, val, response.data.data[val] );
              }
            }
          } else {
            this.$Message.error(response.data.msg)
          }
        })
    },
    //end
    cleanLogData(){
        // 清空日志
        let self = this;
        self.log_desc_zh = '';
        self.log_desc_en = '';
    },
    //end
    // 新增文字直播
    apiAddLog( insert){
        let self = this;
        if (self.spinShow){
            if (self.panel_add_log  ){
                self.$Message.error('处理中 请稍后..');
            }
            return
        }
        // console.log(JSON.stringify(self.new_log));
        self.spinShow = true;
        self.loading = true;
        if (self.new_log.key_index === -1){
            var params = {
                desc_zh: self.log_desc_zh,
                desc_en: self.log_desc_en,
                time_left: self.new_log.time_left,
                home_score: self.new_log.home_score,
                away_score: self.new_log.away_score,
                sport_id:self.sport_id,
                match_id: self.match_id,
                box_num: self.box_num,
                team: self.new_log.team,
                neighborkey_zh: self.new_log.neighborkey_zh || '',
                neighborkey_en: self.new_log.neighborkey_en || '',
                insert: self.new_log.insert || 0,
                raw: self.new_log.raw,
            }
            self.panel_add_log = false;
            // 上传到数据库
            LineAddTextlive( params ).then(response => {
              if (response.data.code === 0) {
                // 重新获取文字直播
                // console.log(JSON.stringify(self.new_log))
                self.apiGetLog(2, self.box_num);
              } else {
                this.$Message.error(response.data.msg);
              }
            })
        }else{
            // 更新到key
            var params = {
                sport_id: self.sport_id,
                match_id: self.match_id,
                box_num: self.box_num, 
                key_index: self.new_log.key_index,
                params: {
                    desc_zh: self.log_desc_zh,
                    desc_en: self.log_desc_en,
                    log: self.log,
                    time_left: self.new_log.time_left,
                    home_score: self.new_log.home_score,
                    away_score: self.new_log.away_score,
                    team: self.new_log.team,
                    raw: self.new_log.raw,
                },
            }
            // 
            // console.log(params)
            LineUpdateTextlive(params).then(response => {
                if (response.data.code == 0) {
                    // 重新获取文字直播
                    self.$Message.success('更新成功');
                    self.apiGetLog(2, self.box_num);
                } else {
                    self.$Message.error(response.data.msg)
                }
            })
        }
        self.cleanLogData();
        self.spinShow = false;
        self.loading = false;
    },
    // end
    // end
    // 长连接
    // socketConnetc (topic) {
    //   let self = this
    //   self.client = new Client({
    //     topic: `${topic}`,
    //     message (t, msg) {
    //       if (msg.type ===1 ){
    //           let msgData = msg.data;
    //           // 判断box-num是否需要更新
    //           if (self.box_num == self.data.box_num){
    //              var update_box_num = true;
    //           } else{
    //              var update_box_num = false;
    //           }

    //           for (var val in msgData) {
    //               // alert(val + " " + myJson[val]);//输出如:name
    //               self.data[val] = msgData[val];
    //           }
    //           if (update_box_num && self.panel_add_log == false){
    //             self.box_num = self.data.box_num || '1';
    //           }
    //       }else if (msg.type===2){
    //           let msgData = msg.data;
    //           // console.log(msgData);
    //           // 选手
    //           var new_home_players = msgData.home_players || [];
    //           var new_away_players = msgData.away_players || [];

    //           var home_players = self.data.home_players;
    //           var away_players = self.data.away_players;

    //           var home_player_ids = [];
    //           var away_player_ids = [];

    //           var new_home_player_ids = [];
    //           var new_away_player_ids = [];

    //           for (var index in new_home_players){
    //               // 长连接中的数据 - 出厂置为1
    //               new_home_players[index].gray = 0;
    //               new_home_player_ids.push(new_home_players[index].id);
    //           }
    //           for (var index in new_away_players){
    //               new_away_players[index].gray = 0;
    //               new_away_player_ids.push(new_away_players[index].id);
    //           }
    //           // 设置新的选手
    //           // 首发与替补
    //           for (var _index in home_players ){
    //             if ( ! new_home_player_ids.includes( home_players[_index].id )){
    //                 // 新增选手
    //                 home_players[_index].gray = 1;  // 置灰
    //                 new_home_players.push( home_players[_index] );
    //             }
    //           }
    //           for (var _index in away_players ){
    //             if ( ! new_away_player_ids.includes( away_players[_index].id )){
    //                 // 新增选手
    //                 away_players[_index].gray = 1;  // 置灰
    //                 new_away_players.push( away_players[_index] );
    //             }
    //           }
    //           new_home_players.sort( function(a,b) {
    //               if (a.gray !== b.gray ){
    //                   return a.gray - b.gray;
    //               }else
    //               if (a.is_show !== b.is_show ){
    //                   return a.is_show - b.is_show;
    //               }
    //               else if (a.is_shou !== b.is_shou){
    //                   return a.is_shou - b.is_shou;
    //               }else{
    //                   return
    //               }
    //           })
    //           new_away_players.sort( function(a,b) {
    //               if (a.gray !== b.gray ){
    //                   return a.gray - b.gray;
    //               }else
    //               if (a.is_show !== b.is_show ){
    //                   return a.is_show - b.is_show;
    //               }else if (a.is_shou !== b.is_shou){
    //                   return a.is_shou - b.is_shou;
    //               }else{
    //                   return
    //               }
    //           })
    //           self.data['home_players'] = new_home_players;
    //           self.data['away_players'] = new_away_players;

    //       }else if (msg.type === 3){
    //           // 纳米数据文字直播 长连接
    //           let msgData = msg.data;
    //           // console.log(msgData);

    //           // 获取所有的  目前的box_nums
    //           var old_box_nums = []
    //           for ( var _index in self.tab_box_nums){
    //               // console.log(tab)
    //               old_box_nums.push( self.tab_box_nums[_index].name );
    //           }
    //           var need_update_tab = false;

    //           for (var val in msgData) {
    //               // alert(val + " " + myJson[val]);//输出如:name
    //               // self.nami_text_lives[val] = msgData[val];
    //               if (! old_box_nums.includes( val )  ){
    //                   need_update_tab = true;
    //               }
    //               if ( !self.nami_text_lives.hasOwnProperty( val )){
    //                   // self.nami_text_lives[val] = [];
    //                   var raw_tlives = [];
    //               }else{
    //                   var raw_tlives = self.nami_text_lives[val];
    //               }
    //               var new_tlives = msgData[val];
    //               for (var i=0; i<new_tlives.length; i++ ){
    //                   raw_tlives.unshift(  new_tlives[i] );
    //                   self.$set(self.nami_text_lives, val, raw_tlives );
    //               }
    //           }
    //           if( need_update_tab ){
    //               // console.log("GET ALL TABS and DATAS");
    //               self.getTabsData(); // 获取所有tabs
    //               self.apiGetLog(1);
    //               self.apiGetLog(0);
    //           }
    //       }else if (msg.type === 4){
    //           //
    //           let msgData = msg.data;
    //           var box_num = msgData.box_num || 0;
    //           var kwargs = msgData.data;
    //           if ( kwargs ){
    //               var auto_bing = kwargs.auto_bing;
    //               if (auto_bing){
    //                   // 存在自动并入的数据 - 刷新表格
    //                   self.apiGetLog(0, box_num);
    //               }
    //           }
    //       }
    //       if ( msg.type === 2){
    //       }
    //     },
    //     connect(){
    //       // if (self.get_data_count > 1){
    //           self.apiGetLog(1);
    //       // }
    //     },
    //   })
    // },
    // end
    rowClassName (row, index) {
        let self = this;
        if (row.source === 0) {
            return 'demo-table-add-row';
        } else if (row.source === 1) {
            return 'demo-table-bing-row';
        }
        return '';
    },
    //end
    //end
    deleteLogs(key_index){
        let self = this;
        self.loading = true;
        var params = {
            sport_id: self.sport_id,
            match_id: self.match_id,
            box_num : self.box_num,
            key_index: key_index,
        }
        LineDeleteTextlive(params).then(response =>{
            if (response.data.code == 0) {
                // 重新获取文字直播
                self.$Message.success('删除成功');
                self.apiGetLog(2, self.box_num);
            } else {
                self.$Message.error(response.data.msg)
            }
        })
        self.loading = false;
    },
    //end
    apiSelectChange(rows){
        let self = this;
        self.choosed_logs = {}
        for (var i =0; i< rows.length; i++){
            var row = rows[i]
            var _key = row.key;
            var box_num = String( row.box_num );
            if ( !self.choosed_logs.hasOwnProperty( box_num )){
                self.choosed_logs[box_num] = []
            }
            // 写入到记录之中
            self.choosed_logs[box_num].push( _key );
        }
    },
    // End
    // dragLogs(index1, index2){
    //     let self = this;
    //     if (index1 != index2){
    //         var data_list = self.text_lives[self.box_num];
    //         var ldata_1 = data_list[index1];
    //         var ldata_2 = data_list[index2];
    //         // 交换1和2的顺序
    //         var params = {
    //             sport_id: self.sport_id,
    //             match_id: self.match_id,
    //             key1: ldata_1.key,
    //             key2: ldata_2.key,
    //             box_num: self.box_num,
    //         }
    //         // 交换 排序
    //         DragTextlive(params).then( response =>{
    //             if (response.data.code == 0) {
    //               // 重新获取文字直播
    //               // self.$Message.success('删除成功');
    //               self.apiGetLog(0, self.box_num);
    //             } else {
    //               self.$Message.error(response.data.msg)
    //             }
    //         } )
    //     }
    // },
    // // end
    // apiReplaceText(){
    //     let self = this;
    //     var params = {
    //       sport_id: self.sport_id,
    //       match_id: self.match_id,
    //       box_num: self.box_num,
    //       params: self.replaced_log,
    //     }
    //     ReplaceTextlive(params).then( response =>{
    //         if (response.data.code == 0) {
    //           // 重新获取文字直播
    //           // self.$Message.success('删除成功');
    //           self.apiGetLog(0, self.box_num);
    //         } else {
    //           self.$Message.error(response.data.msg)
    //         }
    //     } )
    // },
    //end
    showAddModel(){
        let self = this;
        self.new_log =  {
            "key_index": -1,
            "time_left": 0,
            "home_score": 0,
            "away_score": 0,
            "team": 0,  // 0中立 1主队 2客队
            "neighborkey_zh": '',
            "neighborkey_en": '',
            "insert": 0
        }
        self.panel_add_log = true;
    },
    //  end
    addLogMap(s, _index){
        let self = this;
        if (! self.log_map[s] ){
            self.log_map[s] = []
        }
        self.log_map[s].push(_index);
    },
    // end
    handleContextMenu (row) {
        this.table_edit_row = row;
    },
    handleContextMenuInsertUp(row){
        let self = this;
        self.new_log =  {
            "key_index": -1,
            "time_left": 0,
            "home_score": 0,
            "away_score": 0,
            "team": 0,  // 0中立 1主队 2客队
            "neighborkey_zh": self.table_edit_row.key_zh,
            "neighborkey_en": self.table_edit_row.key_en,
            "insert": 1
        }
        self.panel_add_log = true;
        // self.apiAddLog(self.table_edit_row.key_zh, self.table_edit_row.key_en,  1);
    },
    //end
    handleContextMenuInsertDown(row){
        let self = this;
        self.new_log =  {
            "key_index": -1,
            "time_left": 0,
            "home_score": 0,
            "away_score": 0,
            "team": 0,  // 0中立 1主队 2客队
            "neighborkey_zh": self.table_edit_row.key_zh,
            "neighborkey_en": self.table_edit_row.key_en,
            "insert": 2
        }
        self.panel_add_log = true;
        console.log(self.new_log);
        // self.apiAddLog(self.table_edit_row.key_zh, self.table_edit_row.key_en, 2);
    },
    //end
    // openDemoPage(){
    //     let self=this;
    //     let query = { match_id: self.match_id, sport_id: self.sport_id, box_num: self.box_num}
    //     // vm.$router.push({path: '/numer', query: query});
    //     let resolve_data = self.$router.resolve({ path: '/text_live/demo', query: query })
    //     window.open(resolve_data.href, '_blank')
    // },
    // end
    // end
  },
  mounted () {
    let self = this;
    this.sport_id = this.$route.query.sport_id
    this.match_id = this.$route.query.match_id
    this.getTabsData();
    // var a = msgpack.serialize({1:1});
    self.apiGetLog(2); // 获取线上的数据
  },
  created () {
    this.sport_id = this.$route.query.sport_id
    this.match_id = this.$route.query.match_id
    // var topic = "namicms/match_detail/"+ this.sport_id + "_" + this.match_id;
    // this.socketConnetc(topic); //
  },
  watch: {
    // end
    panel_add_log(value){
        let self=this;
        if (value){
            if (self.new_log.source == 0 && (self.new_log.key==='') ){
                // 新增
                self.new_log.time_left =  0;
            }else{
            }
        }else{
            self.log = '';
            self.log_desc_en = '';
            self.log_desc_zh = '';
            self.new_log.key = '';
        }
    },
    // end
    box_num(value){
      let self = this;
      if (!self.text_lives[String(value)] || self.text_lives[String(value)].length ===0 ){
        self.apiGetLog(2, value);
      }
    },
    //end
  },
  computed: {
    match_time: function(){
      let self = this;
      if (self.data){
        return moment.unix(self.data.match_time).format('YYYY-MM-DD HH:mm')
      }
    }
  }
}
</script>

<style >
  .team_name {
     font-size:10px
  }

  .ivu-table .demo-table-bing-row td:first-child{
      background-color: #2db7f5;
      color: #fff;
  }

  .player_bu{
      background: #2db7f5 !important;
  }

  .ivu-table .player-table-hide-row td{
      background-color:  #EEE;
  }

  .log_score {
    font-size:10px;
    color:red;
  }
</style>

<style scoped>
  .ivu-form-item{
    margin-bottom: 0px;
  }
</style>
